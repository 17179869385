/** @format */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/////////////////////////////////// TOOGLER ICON COLOR CHANGE COLOR //////////////////////////
* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-sizing: border-box;
}
img {
  -webkit-user-drag: none; /* Disable dragging of image */
  -webkit-user-select: none; /* Disable selecting of image */
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img:after {
  content: ""; /* Add content */
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1; /* Make sure it's on top of the image */
  background-color: rgba(
    0,
    0,
    0,
    0
  ); /* Set the background color to transparent */
}

img::-webkit-context-menu {
  display: none; /* Disable right-click context menu on Webkit browsers */
}

img::-moz-context-menu {
  display: none; /* Disable right-click context menu on Firefox */
}

img:focus,
img:active {
  outline: none; /* Remove outline on click or focus */
}
a {
  text-decoration: none !important;
}
body {
  /* global 94%+ browsers support */
  // background:linear-gradient(25deg, rgba(160, 3, 78, 1) 0%, rgba(31, 7, 15, 1) 47%, rgba(160, 3, 78, 1) 100%);
  background: linear-gradient(
    25deg,
    rgba(160, 3, 78, 1) 0%,
    rgba(31, 7, 15, 1) 47%,
    rgba(160, 3, 78, 1) 100%
  ) !important;
  background-position: 50%;
  background-repeat: repeat;

}

body::-webkit-scrollbar-track {
  background: #f7fbff !important;
}

body::-webkit-scrollbar-thumb {
  background: #c51559 !important;
  border-radius: 16px;
}

body::-webkit-scrollbar {
  width: 3px;
}
::selection {
  background: #c51559;
  color: #fff !important;
}

.navbar .navbar-toggler-icon {
  width: 24px;
  height: 19px;
  background-image: none !important;
  position: relative;
  border-bottom: 3px solid #ffffff;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 3px;
  background-color: #ffffff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after {
  top: 8px;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.navbar-toggler {
  background: transparent;
  border: 0 !important;
  box-shadow: none !important;
}
/*========== SCROLL UP ==========*/
.scroll-sc {
  // box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
  background: linear-gradient(
    25deg,
    rgba(160, 3, 78, 1) 0%,
    rgba(160, 3, 78, 1) 47%,
    rgba(160, 3, 78, 1) 100%
  ) !important;
  transition: all 0.3s !important;
  z-index: 2 !important;
}
.show-scroll {
  bottom: 5rem;
}
.offcanvas {
  backdrop-filter: blur(10px) !important;
  background-color: #00000080 !important;
}
.footer-section {
  a {
    color: rgb(255, 255, 255);
    margin: 0px 4px;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    &:hover {
      color: #eb008b;
    }
  }
}
.trams-box {
  -webkit-backdrop-filter: blur(10px) !important;
  backdrop-filter: blur(10px) !important;
  background: rgba(197, 21, 89, 0.286);
  border: 1px dashed #c51559;
  border-radius: 8px;
  color: #fff;

  padding: 30px;
  width: 100%;
  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 130%;
    text-align: center;
    color: #ffffff;
  }
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 130%;

    color: #ffffff;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #dadee3;
  }
}
.timer-section {
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;

    color: #ffffff;
  }
  .timer {
    span {
      color: #000000;
      font-weight: 600;
      font-size: 22px;
      background-color: #ffffff;
      padding: 0.5rem;
      margin: 0.5rem;
      border-radius: 4px;
    }
  }
}
/////////////////////////// HEADER SECTION ////////////////////////////////////////
.Header-Section {
  // position: sticky !important;
  // top: 0px !important;
  .navbar-brand {
    font-family: "Cabin", sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #ffffff;

    &:hover {
      color: #ffffff;
    }
  }

  .navbar-item {
    position: relative;

    a {
      font-family: "Cabin", sans-serif;
      text-transform: uppercase;
      color: #ffffff;
      -webkit-transform: skewX(-18deg);
      transform: skewX(-18deg);
      max-width: 130px;
      font-size: 0.75rem;
      height: 40px;
      border-color: #dbdbdb;
      border-width: 1px;
      align-items: center;
      border-radius: 4px;
      box-shadow: none;

      display: inline-flex;
      justify-content: center;

      line-height: 1.5;
      padding-bottom: calc(0.5em - 1px);
      padding-left: calc(0.75em - 1px);
      padding-right: calc(0.75em - 1px);
      padding-top: calc(0.5em - 1px);
      cursor: pointer;
      text-decoration: none;
      transition: all 0.6s ease-out !important;
      background: -webkit-gradient(
        linear,
        left bottom,
        left top,
        from(#eb689a),
        to(#c51559)
      ) !important;
      background: linear-gradient(to top, #eb689a, #c51559) !important;
      opacity: 1;
      z-index: 0;
      margin-left: 20px;
      justify-content: center;

      &:hover {
        box-shadow: 0 14px 26px -12px rgba(127, 0, 255, 0.42),
          0 4px 23px 0px rgba(0, 0, 0, 0.12),
          0 8px 10px -5px rgba(63, 0, 252, 0.2) !important;
        opacity: 0.95;
      }
    }
  }

  ul li a {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: "Cabin", sans-serif;
    text-transform: uppercase;
    font-size: 0.75rem;
    color: #ffffff !important;
    padding: 0.5rem 0.75rem;
  }
}
//////////////////////////// HERO SECTION ///////////////////////////////////

.Hero-Section {
  // height: 100vh;

  .content-section {
    h2 {
      color: rgb(255, 255, 255);
      font-size: 2.6rem;
      font-weight: 400;
      text-align: center;
      letter-spacing: 10px;
      text-transform: uppercase;
      margin-bottom: 1rem;
      margin-top: 0rem;
      -webkit-background-clip: text;
      background: -webkit-linear-gradient(
          top,
          rgb(255, 255, 255),
          rgb(56, 73, 90)
        )
        text;
    }

    h3 {
      font-size: 2rem;
      font-weight: 400;
      line-height: 3rem;
      letter-spacing: 2px;
      // margin-top: 2.5rem;
      margin-bottom: 2.5rem;
      background: linear-gradient(
        to left,
        rgb(254, 227, 131),
        rgb(248, 108, 13),
        rgb(151, 82, 89),
        rgb(88, 151, 160),
        rgb(147, 187, 228),
        rgb(216, 250, 241),
        rgb(255, 255, 255)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    h6 {
      color: rgb(187, 187, 187);
      line-height: 1.5;
      margin-bottom: 1rem;
      font-weight: 400;
      letter-spacing: 4px;
      font-size: 1.7rem;
    }

    h5 {
      color: rgb(255, 255, 255);
      font-size: 2rem;
      font-weight: 400;
      text-align: center;
      letter-spacing: 4px;
      text-transform: uppercase;
      -webkit-background-clip: text;
      background: -webkit-linear-gradient(
          top,
          rgb(255, 255, 255),
          rgb(56, 73, 90)
        )
        text;
    }
  }
}

.btn-launch-buy {
  .btn-launch {
    background: linear-gradient(to top, #8c0028, #c51559) !important;
  }
  .btn-buy {
    background: linear-gradient(
      to top,
      #1fc7d4ff,
      rgb(39, 135, 142)
    ) !important;
  }
  .btn-sale {
    background: linear-gradient(to top, #f95192, #e1a8be) !important;
    font-weight: 600;
    color: #000;
  }
}
.annn{
  animation: myAnim 2s ease 0s infinite normal forwards;

}
.btn-section {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  margin-top: 2rem;

  a {
    font-family: "Cabin", sans-serif;
    text-transform: uppercase;
    color: #ffffff;
    -webkit-transform: skewX(-18deg);
    transform: skewX(-18deg);
    width: 230px;
    font-size: 0.75rem;
    height: 40px;
    border-color: #dbdbdb;
    border-width: 1px;
    align-items: center;
    border-radius: 4px;
    box-shadow: none;

    display: inline-flex;

    line-height: 1.5;
    padding-bottom: calc(0.5em - 1px);
    padding-left: calc(0.75em - 1px);
    padding-right: calc(0.75em - 1px);
    padding-top: calc(0.5em - 1px);
    cursor: pointer;
    text-decoration: none;
    transition: all 0.6s ease-out !important;
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#eb689a),
      to(#c51559)
    ) !important;
    background: linear-gradient(to top, #eb689a, #c51559) !important;
    opacity: 1;
    z-index: 0;
    margin-left: 20px;
    justify-content: center;

    &:hover {
      box-shadow: 0 14px 26px -12px rgba(127, 0, 255, 0.42),
        0 4px 23px 0px rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(63, 0, 252, 0.2) !important;
      opacity: 0.95;
    }
  }
}
@keyframes myAnim {
  0%,
  100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }

  15% {
    transform: translateX(-30px) rotate(-6deg);
  }

  30% {
    transform: translateX(15px) rotate(6deg);
  }

  45% {
    transform: translateX(-15px) rotate(-3.6deg);
  }

  60% {
    transform: translateX(9px) rotate(2.4deg);
  }

  75% {
    transform: translateX(-6px) rotate(-1.2deg);
  }
}
.footer-btn {
  a {
    font-family: "Cabin", sans-serif;
    text-transform: uppercase;
    color: #ffffff;
    -webkit-transform: skewX(-18deg);
    transform: skewX(-18deg);
    width: 230px;
    font-size: 0.75rem;
    height: 40px;
    border-color: #dbdbdb;
    border-width: 1px;
    align-items: center;
    border-radius: 4px;
    box-shadow: none;

    display: inline-flex;

    line-height: 1.5;
    padding-bottom: calc(0.5em - 1px);
    padding-left: calc(0.75em - 1px);
    padding-right: calc(0.75em - 1px);
    padding-top: calc(0.5em - 1px);
    cursor: pointer;
    text-decoration: none;
    transition: all 0.6s ease-out !important;
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#eb689a),
      to(#c51559)
    ) !important;
    background: linear-gradient(to top, #eb689a, #c51559) !important;
    opacity: 1;
    z-index: 0;
    margin-left: 20px;
    justify-content: center;

    &:hover {
      box-shadow: 0 14px 26px -12px rgba(127, 0, 255, 0.42),
        0 4px 23px 0px rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(63, 0, 252, 0.2) !important;
      opacity: 0.95;
      color: #000 !important;
    }
  }
}
// ecosystem-section

.oscar-section {
  background: url(./bg-01-01.png) no-repeat top #2c0615;
  background-size: cover;
  width: 100%;
  // object-fit: contain;
  height: 100vh;
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    max-width: 500px;
    letter-spacing: 2px;
    font-size: 42px;
    line-height: 130%;
    margin-bottom: 20px;
    color: #ffffff;
  }

  p {
    max-width: 500px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #dadee3;
  }
  .btn-section {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    position: relative;
    margin-top: 2rem;

    a {
      font-family: "Cabin", sans-serif;
      text-transform: uppercase;
      color: #ffffff;
      -webkit-transform: skewX(-18deg);
      transform: skewX(-18deg);
      width: 230px;
      font-size: 0.75rem;
      height: 40px;
      border-color: #dbdbdb;
      border-width: 1px;
      align-items: center;
      border-radius: 4px;
      box-shadow: none;

      display: inline-flex;

      line-height: 1.5;
      padding-bottom: calc(0.5em - 1px);
      padding-left: calc(0.75em - 1px);
      padding-right: calc(0.75em - 1px);
      padding-top: calc(0.5em - 1px);
      cursor: pointer;
      text-decoration: none;
      transition: all 0.6s ease-out !important;
      background: -webkit-gradient(
        linear,
        left bottom,
        left top,
        from(#eb689a),
        to(#c51559)
      ) !important;
      background: linear-gradient(to top, #eb689a, #c51559) !important;
      opacity: 1;
      z-index: 0;

      justify-content: center;

      &:hover {
        box-shadow: 0 14px 26px -12px rgba(127, 0, 255, 0.42),
          0 4px 23px 0px rgba(0, 0, 0, 0.12),
          0 8px 10px -5px rgba(63, 0, 252, 0.2) !important;
        opacity: 0.95;
      }
    }
  }
  a.w-br {
    background: transparent !important;
    border: 2px solid;
    border-image: #c51559;
  }
}
// ecosystem-section

.ecosystem-section {
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 130%;
    text-align: center;
    margin-bottom: 10px;
    color: #ffffff;
  }

  p {
    max-width: 531px;
    margin: auto;
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #dadee3;
  }
  .feature {
    border: 1px solid #8c0028;
    border-radius: 100%;
    padding: 20px 30px 30px;
    width: 320px;
    height: 320px;

    h5 {
      color: #fff;
      font-weight: 600;
    }
    p {
      color: #8c8c8c;
      text-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      text-align: center;
      letter-spacing: 0.005em;
    }
  }
  .icon-1 img {
    top: -65px;
    width: 40px;
  }
  .feature img {
    position: relative;
  }
  .icon-2 img {
    top: -65px;
    width: 60px;
  }
  .icon-3 img {
    top: -56px;
    width: 60px;
  }
}
// .display-bg::before {
//   content: "Coming Soon" !important;
//   position: absolute !important;
//   left: -40px !important;
//   // top: 140px !important;
//   margin-top: 120px;
//   transform: rotate(270deg);
//   z-index: 1 !important;
//   color: whitesmoke !important;
//   text-shadow: -2px -2px 5px #000;
//   font-size: 40px !important;
//   text-align: center !important;
// }
///////////////////////////   CORE FEATRE SECTION //////////////////
.CoreFeature-section {
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 130%;
    text-align: center;
    margin-bottom: 10px;
    color: #ffffff;
  }

  p {
    max-width: 531px;
    margin: auto;
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #dadee3;
  }

  .card-bg {
    border-radius: 20px;
    padding: 30px 24px;
    min-height: 400px !important;
    // border-bottom: 2px solid #565e6b;
    // border-right: 0.5px solid #565e6b;
    // border-left: 0.5px solid #565e6b;
    //  background: rgb(101 77 86 / 29%);
    // background: rgb(101 77 86 / 29%);
    // border-radius: 5px;
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
    background: rgba(197, 21, 89, 0.286);
    border: 1px dashed #c51559;

    &:hover {
      border: 1px dotted #c51559;
    }

    .title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
      color: #ffffff;
      margin: 30px 0px 20px 0px;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 170%;
      letter-spacing: 0.008em;
      color: #ebeef2;
    }

    .btn-conn {
      font-family: "Cabin", sans-serif;
      text-transform: uppercase;
      color: #ffffff;
      -webkit-transform: skewX(-18deg);
      transform: skewX(-18deg);
      width: 180px;
      font-size: 0.75rem;
      height: 40px;
      border-color: #dbdbdb;
      border-width: 1px;
      align-items: center;
      border-radius: 4px;
      box-shadow: none;
      display: inline-flex;
      line-height: 1.5;
      padding-bottom: calc(0.5em - 1px);
      padding-left: calc(0.75em - 1px);
      padding-right: calc(0.75em - 1px);
      padding-top: calc(0.5em - 1px);
      cursor: pointer;
      text-decoration: none;
      transition: all 0.6s ease-out !important;
      background: -webkit-gradient(
        linear,
        left bottom,
        left top,
        from(#eb689a),
        to(#c51559)
      ) !important;
      background: linear-gradient(to top, #eb689a, #c51559) !important;
      opacity: 1;
      z-index: 0;
      justify-content: center;

      &:hover {
        box-shadow: 0 14px 26px -12px rgba(127, 0, 255, 0.42),
          0 4px 23px 0px rgba(0, 0, 0, 0.12),
          0 8px 10px -5px rgba(63, 0, 252, 0.2) !important;
        opacity: 0.95;
      }
    }
  }

  .carousel-container {
    padding: 80px 0px 50px 0px;
    z-index: 1000;
  }

  .border-red {
    border: 1px solid #f66060;
  }
}

////////////////////////////  AppSection Section ///////////////////////////

.AppSection-section {
  padding-top: 170px !important;

  .content-section {
    // backdrop-filter: blur(10px) !important;
    border-radius: 20px;
    // border-bottom: 2px solid #565e6b;
    // border-right: 0.5px solid #565e6b;
    // border-left: 0.5px solid #565e6b;
    // background: rgb(101 77 86 / 29%);
    // border-radius: 5px;
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
    background: rgba(197, 21, 89, 0.286);
    border: 1px dashed #c51559;

    &:hover {
      border: 1px dotted #c51559;
    }
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #ebeef2;
  }
}

//////////////////////////////display-bg//////   LearMore Section  /////////////////////////////////////

.LearMore-section {
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 130%;
    text-align: center;
    // margin-bottom: 30px;
    color: #ffffff;
  }

  p {
    max-width: 531px;
    margin: auto;
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #dadee3;
  }

  .card-bg {
    border-radius: 20px;
    padding: 30px 24px;
    // border-bottom: 2px solid #565e6b;
    // border-right: 0.5px solid #565e6b;
    // border-left: 0.5px solid #565e6b;
    // overflow: inherit;
    // background: rgb(101 77 86 / 29%);
    // backdrop-filter: blur(10px) !important;

    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
    background: rgba(197, 21, 89, 0.286);
    border: 1px dashed #c51559;

    &:hover {
      border: 1px dotted #c51559;
    }
    .title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      color: #ffffff;
      font-size: 20px;
      line-height: 130%;
    }

    .arrow-icon {
      color: #fff;
      font-size: 26px;
    }

    .arrow-icon-active {
      background: linear-gradient(90deg, #ec008c 4.81%, #fc6767 100%);
      box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(10px) !important;
      border-radius: 100px;
      font-size: 26px;
      color: #000;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      line-height: 170%;
      letter-spacing: 0.008em;
      text-align: start;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      margin-top: 27px;
    }
  }

  .border-red {
    border: 1px solid #f66060;
  }

  .carousel-container {
    padding: 80px 0px 50px 0px;
    z-index: 1000;
  }
}

//////////////////////////////  TrustedPartners Section   //////////////////////

.TrustedPartners-section {
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 130%;
    text-align: center;
    color: #ffffff;
    margin-bottom: 70px;
  }

  .img-section {
    border-radius: 10px;
    // border-bottom: 2px solid #565e6b;
    // border-right: 0.5px solid #565e6b;
    // border-left: 0.5px solid #565e6b;
    // overflow: inherit;
    // background: rgb(101 77 86 / 29%);
    // backdrop-filter: blur(10px) !important;
    width: 100%;
    height: 80px;
    box-shadow: 5px 5px 30px 0px #00000040;
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
    background: rgba(197, 21, 89, 0.286);
    border: 1px dashed #c51559;

    &:hover {
      border: 1px dotted #c51559;
    }
  }
}
.card-bgs {
  display: flex;
  justify-content: center;
  align-items: center;
}
//team
.blockmover-row2 {
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 130%;
    text-align: center;
    color: #ffffff;
    margin-bottom: 30px;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    line-height: 170%;
    letter-spacing: 0.008em;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
  }
}

.team-cards {
  -webkit-backdrop-filter: blur(10px) !important;
  backdrop-filter: blur(10px) !important;
  background: rgba(197, 21, 89, 0.286) !important;
  border: 1px dashed #c51559 !important;
  border-radius: 6px !important;
  text-align: center;
  margin-top: 30px;
  h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 170%;
    text-align: center;
    color: #dadee3;
    margin-top: 20px;
  }
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.008em;
    color: #c8cbd0;
  }
  .team-img {
    // clip-path: polygon(
    //   20% 0%,
    //   80% 0%,
    //   100% 20%,
    //   100% 80%,
    //   80% 100%,
    //   20% 100%,
    //   0% 80%,
    //   0% 20%
    // );

    border-radius: 20px;
    background-size: contain !important;
    object-fit: contain !important;
    height: 200px;
    width: 200px;
    background: #000;
    filter: grayscale(100%);
  }
}
.modal-content {
  backdrop-filter: blur(10px) !important;
  background: rgba(197, 21, 89, 0.886) !important;
  padding: 20px;
  border: 2px dotted #fff !important;
  margin-top: 100px !important;
  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-size: 26px;
    line-height: 130%;
    text-align: center;
    margin-top: 20px;
    color: #ffffff;
  }
}

#mp3Player {
  width: 100%;
  max-width: 200px;
}

.controls {
  text-align: center;
  padding: 10px;
  background-color: #8c0028;
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border: 2px groove #c51559;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#cedce7+0,596a72+100;Grey+3D+%231 */
  background: #8c0028; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #8c0028 0%,
    #c51559 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #8c0028 0%,
    #c51559 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #8c0028 0%,
    #c51559 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.controls button {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  cursor: pointer;
  border-radius: 100px;
  margin: 0px 5px;
  border: 1px solid #c0d3e2;
  color: #2c3236;
  box-shadow: 0px 1px 1px 0px #404040;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#dce8f4+0,7c8d9e+100 */
  background: #dce8f4; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #dce8f4 0%,
    #7c8d9e 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #dce8f4 0%,
    #7c8d9e 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #dce8f4 0%,
    #7c8d9e 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dce8f4', endColorstr='#7c8d9e',GradientType=0 ); /* IE6-9 */
}

.controls button:hover {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e5f1fc+0,b1c8e0+100 */
  background: #e5f1fc; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #e5f1fc 0%,
    #b1c8e0 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #e5f1fc 0%,
    #b1c8e0 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #e5f1fc 0%,
    #b1c8e0 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5f1fc', endColorstr='#b1c8e0',GradientType=0 ); /* IE6-9 */
}

.controls button:active {
  background: #84949d;
  box-shadow: 0px 0px 0px;
  border: 1px solid #454d52;
}
.close {
  color: #fff;
  float: right;
  font-size: 28px;
  position: absolute;
  right: 10px;
  top: 0px;
  font-weight: bold;
  background: #8c0028;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.close:hover,
.close:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
//////////////////////////////   FAQs SEction ///////////////////////
.news {
  background: #c51559;
  padding-bottom: 0px;
  padding-top: 5px;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.img-box {
  min-height: 50px !important;
  // background: red;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Faqs-section {
  .faq-row-wrapper {
    .faq-title {
      justify-content: center !important;
      border: none;

      h2 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 130%;
        margin-bottom: 30px;
        color: #ffffff;
        margin-bottom: 70px;
      }
    }

    .faq-body {
      .faq-row {
        border: 1px solid rgba(197, 21, 89, 0.286);
        box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.25) !important;
        -webkit-backdrop-filter: blur(100px);
        backdrop-filter: blur(100px);
        padding: 20px 0px 20px 10px;

        .icon-wrapper {
          margin: 20px 20px 0px 0px !important;
        }

        .row-title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          line-height: 130%;
          color: #ffffff;
        }

        .number-dev {
          padding: 0px 25px 0px 50px;
          background: linear-gradient(90deg, #ec008c 4.81%, #fc6767 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }

        .icon-wrapper {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background-color: #2a222e;
          border: 1px solid #565d6c;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .row-content {
          .row-content-text {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 170%;
            letter-spacing: 0.005em;
            color: #6d7580;
          }
        }
      }
    }

    .faq-body > :first-child {
      border-radius: 20px 20px 0px 0px;
      border-top: 1px solid #501351;
    }

    .faq-body > :last-child {
      border-radius: 0px 0px 20px 20px;
      border-bottom: 1px solid #501351;
    }
  }
}

////////////////////////  MEdia Querrry  ////////////////////

@media only screen and (max-width: 600px) {
  .display-bg::before {
    display: none;
  }
  .navbar-collapse {
    margin-top: 20px;

    .navbar-item {
      margin-top: 20px;
    }
  }
  .btn-section a {
    margin: 0px;
  }
  .Hero-Section .content-section {
    h2 {
      font-size: 2rem;
    }
    h3,
    h5,
    h6 {
      font-size: 1.6rem;
    }
  }

  .CoreFeature-section h2 {
    font-size: 28px;
    font-weight: 700;
  }

  .CoreFeature-section,
  .LearMore-section .carousel-container {
    padding: 60px 0px 50px 0px !important;
  }

  .CoreFeature-section {
    .react-multi-carousel-item {
      width: 260px !important;
    }
  }

  .AppSection-section {
    padding-top: 65px !important;

    .content-section {
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.005em;
      }
    }
  }

  .LearMore-section {
    .card-bg {
      flex-direction: column;

      img {
        width: 60px;
        border-radius: 10px !important;
        height: 60px;
      }
    }
  }

  .TrustedPartners-section,
  .LearMore-section {
    h2 {
      font-weight: 700;
      font-size: 28px;
      line-height: 130%;
      margin-bottom: 0px;
    }
  }

  .TrustedPartners-section .img-section {
    height: 51px;

    img {
      width: 108px;
    }
  }

  .Faqs-section {
    .faq-row-wrapper {
      .faq-title h2 {
        font-size: 28px;
      }
      .styles_row-title-text__1MuhU {
        font-size: 1rem !important;
      }
      .faq-body .faq-row {
        .number-dev {
          padding: 0px 25px 0px 25px;
        }
        .row-content .row-content-text {
          padding: 0px 25px 0px 70px;
        }
      }
    }
  }
}

//////////////////////////// HERO SECTION ///////////////////////////////////

//////////////////////////// Oppertunity SECTION ///////////////////////////////////

.mobile-cta {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  min-height: 1350px;
  overflow: hidden;
  // opacity: 0.1;
  h1 {
    font-weight: 700;
  }
  .mobile-cta-title {
    font-size: 48px !important;
    line-height: 56px;
    font-weight: 900 !important;

    .gradient {
      background: -webkit-gradient(
        25deg,
        rgba(160, 3, 78, 1) 0%,
        rgb(246, 246, 246) 47%,
        rgba(160, 3, 78, 1) 100%
      );
      background: linear-gradient(
        25deg,
        rgba(160, 3, 78, 1) 0%,
        rgb(255, 255, 255) 47%,
        rgba(160, 3, 78, 1) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: rgba(0, 0, 0, 0);
    }

    @media (min-width: 768px) {
      width: 820px;
      margin: 0 auto;
    }
  }
}

.mobile-cta-inner {
  width: 2566px;
  min-height: 1070px;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.mobile-cta-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  text-align: center;
  // margin-top: 115px;
}

.mobile-center {
  position: relative;
  top: 260px;
  left: -10px;
  margin: 9% 0 0 0;

  .mobile-center-img {
    width: 865px;
    animation: bounce 1s infinite;
    animation: bounce 3s infinite ease-in-out;
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @media (max-width: 1199px) {
    .mobile-center-img {
      width: 782px;
    }
  }

  @media (max-width: 991px) {
    .mobile-center-img {
      width: 640px;
    }
  }

  @media (max-width: 767px) {
    .mobile-center-img {
      width: 312px;
    }
  }
}

.mobile-store-logo {
  position: absolute;
}

.mobile-store-logo.mobile-store-android {
  top: 11%;
  left: 13%;
}

.mobile-store-logo.mobile-store-android .mobile-android-light {
  position: absolute;
  left: 17.7%;
  top: 25.5%;
  width: 64.27432%;
}

.mobile-store-logo.mobile-store-apple {
  bottom: -5%;
  right: -9%;
}

.mobile-store-logo.mobile-store-apple .mobile-apple-light {
  position: absolute;
  left: 17.7%;
  top: 25.5%;
  width: 64.27432%;
}

@media (max-width: 1199px) {
  .display-bg::before {
    left: -4F0px !important;
    font-size: 20px !important;
  }
  .mobile-store-logo.mobile-store-android {
    top: 8%;
    left: 11%;
    transform: scale(0.8);
  }

  .mobile-store-logo.mobile-store-apple {
    bottom: -15%;
    right: -16%;
    transform: scale(0.8);
  }
}

@media (max-width: 991px) {
  .mobile-store-logo.mobile-store-android {
    top: 9%;
    left: -24%;
  }

  .mobile-store-logo.mobile-store-apple {
    bottom: 19%;
    right: -39%;
  }

  .mobile-store-logo svg {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .mobile-store-logo.mobile-store-android {
    top: 3%;
    left: -33%;
    transform: scale(0.6);
  }

  .mobile-store-logo.mobile-store-apple {
    bottom: 21%;
    right: -47%;
    transform: scale(0.6);
  }
}

@media (max-width: 470px) {
  .mobile-store-logo.mobile-store-android {
    top: 3%;
    left: -46%;
  }

  .mobile-store-logo.mobile-store-apple {
    bottom: 21%;
    right: -57%;
  }
}

@media (max-width: 430px) {
  .mobile-store-logo.mobile-store-android {
    top: 5%;
    left: -60%;
  }

  .mobile-store-logo.mobile-store-apple {
    bottom: 25%;
    right: -66%;
  }
}

@media (max-width: 390px) {
  .mobile-store-logo.mobile-store-android {
    top: 9%;
    left: -72%;
  }

  .mobile-store-logo.mobile-store-apple {
    bottom: 25%;
    right: -81%;
  }
}

.mobile-block {
  position: absolute;
  left: -25%;
  top: 25%;
  transform: none !important;

  img {
    width: 445px;
  }

  @media (max-width: 1599px) {
    left: -20%;
    top: 22%;
  }

  @media (max-width: 1499px) {
    left: -17%;
    top: 20%;
  }

  @media (max-width: 1399px) {
    left: -13%;
    top: 17%;
  }
}

.mobile-left {
  position: absolute;
  bottom: -54%;
  left: -45%;
}

.mobile-left img {
  width: 750px;
}

@media (min-width: 992px) {
  .mobile-left {
    bottom: -46%;
  }
}

@media (max-width: 991px) {
  .mobile-left {
    bottom: -46%;
    left: -49%;
  }

  .mobile-left img {
    width: 650px;
  }
}

.mobile-right {
  position: absolute;
  top: 14%;
  right: -35%;
}

.mobile-right img {
  width: 750px;
}

@media (max-width: 1399px) {
  .mobile-right {
    top: 20%;
    right: -30%;
  }

  .mobile-right img {
    width: 650px;
  }
}

@media (max-width: 1199px) {
  .mobile-right {
    top: 20%;
    right: -38%;
  }

  .mobile-right img {
    width: 650px;
  }
}

@media (max-width: 991px) {
  .mobile-right {
    top: 24%;
    right: -43%;
  }

  .mobile-right img {
    width: 580px;
  }
}

.mobile-qr {
  position: relative;
  top: 300px;
}

@media (max-width: 1199px) {
  .mobile-qr {
    top: 360px;
  }
}

@media (max-width: 991px) {
  .mobile-qr {
    top: 0;
    margin-top: 360px;
  }
}

.mobile-qr-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  // background: #121216;
  // box-shadow: 2px 4px 32px 4px rgba(13, 12, 15, 0.3),
  //   inset 0px 3px 1px 2px rgba(0, 0, 0, 0.05);

  img {
    width: 110px;
  }
}

// MEDIA Querry

@media (max-width: 991px) {
  .mobile-qr-icon {
    width: 130px;
    height: 130px;
  }

  .mobile-qr-icon img {
    width: 90px;
  }

  .mobile-cta {
    min-height: initial;
    padding: 56px 0;
  }

  .mobile-cta .container {
    width: auto;
    max-width: 100%;
  }

  .mobile-cta .title h2 {
    font-size: 42px;
    line-height: 50px;
    font-weight: 700;
    letter-spacing: -0.6px;
  }

  .mobile-block {
    display: none;
  }

  .mobile-cta-bg {
    width: 928px;
  }

  .mobile-cta-bg img {
    max-width: 100%;
  }

  .mobile-center {
    left: 0;
  }

  .mobile-right {
    width: 312px;
    top: 21%;
    right: -19%;
  }

  .mobile-right img {
    width: 100%;
  }

  .mobile-left {
    width: 300px;
    bottom: auto;
    top: 61%;
    left: -36%;
  }

  .mobile-left img {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .mobile-center {
    top: 145px;
  }

  .mobile-right {
    top: 23%;
    right: -50%;
  }

  .mobile-left {
    top: 54%;
    left: -60%;
  }

  .mobile-qr {
    margin-top: 211px;
  }
}

.theme-light {
  .mobile-cta {
    .mobile-qr {
      .title {
        color: #0d0d0e !important;
      }

      .store-button {
        box-shadow: none;
      }
    }
  }
}

.mobile-cta {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // margin: 0 0 180px;
  position: relative;
  min-height: 1350px;
  // height: 1070px;
  overflow: hidden;

  @media (max-width: 992px) {
    min-height: 1440px;
  }

  .container {
    width: 1320px;
    max-width: 1320px;
    // margin: 0 auto;
  }

  .mobile-cta-title {
    @media (min-width: 768px) {
      width: 820px;
      margin: 0 auto 0 auto;
    }
  }
}

.mobile-cta-inner {
  width: 2566px;
  min-height: 1070px;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.mobile-cta-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  text-align: center;
  // margin-top: 115px;
}

.mobile-center {
  position: relative;
  top: 260px;
  left: -10px;
  margin: 9% 0 0 0;

  .mobile-center-img {
    width: 865px;
  }

  @media (max-width: 1200px) {
    .mobile-center-img {
      width: 782px;
    }
  }

  @media (max-width: 992px) {
    .mobile-center-img {
      width: 640px;
    }
  }

  @media (max-width: 768px) {
    .mobile-center-img {
      width: 312px;
    }
  }
}

.mobile-store-logo {
  position: absolute;

  &.mobile-store-android {
    top: 11%;
    left: 13%;

    .mobile-android-light {
      position: absolute;
      left: 17.7%;
      top: 25.5%;
      width: 64.27432%;
    }
  }

  &.mobile-store-apple {
    bottom: -5%;
    right: -9%;

    .mobile-apple-light {
      position: absolute;
      left: 17.7%;
      top: 25.5%;
      width: 64.27432%;
    }
  }

  @media (max-width: 1200px) {
    &.mobile-store-android {
      top: 8%;
      left: 11%;
      transform: scale(0.8);
    }

    &.mobile-store-apple {
      bottom: -15%;
      right: -16%;
      transform: scale(0.8);
    }
  }

  @media (max-width: 768px) {
    &.mobile-store-android {
      top: 9%;
      left: -24%;
    }

    &.mobile-store-apple {
      bottom: 19%;
      right: -39%;
    }

    svg {
      width: 100%;
    }
  }

  @media (max-width: 576px) {
    &.mobile-store-android {
      top: 3%;
      left: -33%;
      transform: scale(0.6);
    }

    &.mobile-store-apple {
      bottom: 21%;
      right: -47%;
      transform: scale(0.6);
    }
  }

  @media (max-width: 470px) {
    &.mobile-store-android {
      top: 3%;
      left: -46%;
    }

    &.mobile-store-apple {
      bottom: 21%;
      right: -57%;
    }
  }

  @media (max-width: 430px) {
    &.mobile-store-android {
      top: 5%;
      left: -60%;
    }

    &.mobile-store-apple {
      bottom: 25%;
      right: -66%;
    }
  }

  @media (max-width: 390px) {
    &.mobile-store-android {
      top: 9%;
      left: -72%;
    }

    &.mobile-store-apple {
      bottom: 25%;
      right: -81%;
    }
  }
}

.mobile-block {
  position: absolute;
  left: -25%;
  top: 25%;
  transform: none !important;

  img {
    width: 445px;
  }

  @media (max-width: 1599px) {
    left: -20%;
    top: 22%;
  }

  @media (max-width: 1499px) {
    left: -17%;
    top: 20%;
  }

  @media (max-width: 1399px) {
    left: -13%;
    top: 17%;
  }
}

.mobile-left {
  position: absolute;
  bottom: -54%;
  left: -45%;

  img {
    width: 750px;
  }

  @media (min-width: 992px) {
    bottom: -46%;
  }

  @media (max-width: 992px) {
    bottom: -46%;
    left: -49%;

    img {
      width: 650px;
    }
  }
}

.mobile-right {
  position: absolute;
  top: 14%;
  right: -35%;

  img {
    width: 750px;
  }

  @media (max-width: 1440px) {
    top: 20%;
    right: -30%;

    img {
      width: 650px;
    }
  }

  @media (max-width: 1200px) {
    top: 20%;
    right: -38%;

    img {
      width: 650px;
    }
  }

  @media (max-width: 992px) {
    top: 24%;
    right: -43%;

    img {
      width: 580px;
    }
  }
}

.mobile-qr {
  position: relative;
  top: 300px;

  @media (max-width: 1200px) {
    top: 360px;
  }

  @media (max-width: 768px) {
    top: 0;
    margin-top: 360px;
  }
}

.mobile-qr-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  border: 3px solid #fff;
  // background: #121216;
  // box-shadow: 2px 4px 32px 4px rgba(13, 12, 15, 0.3), inset 0px 3px 1px 2px rgba(0, 0, 0, 0.05);

  img {
    width: 150px;
  }
}

// MEDIA

@media (max-width: 768px) {
  .mobile-qr-icon {
    width: 130px;
    height: 130px;

    img {
      width: 90px;
    }
  }

  .mobile-cta {
    min-height: initial;
    padding: 56px 0;

    .container {
      width: auto;
      max-width: 100%;
    }

    .title {
      h2 {
        font-size: 46px;
        line-height: 40px;
        letter-spacing: -0.6px;
      }
    }
  }

  .mobile-block {
    display: none;
  }

  .mobile-cta-bg {
    width: 928px;

    img {
      max-width: 100%;
    }
  }

  .mobile-center {
    left: 0;
  }

  .mobile-right {
    width: 312px;
    top: 21%;
    right: -19%;

    img {
      width: 100%;
    }
  }

  .mobile-left {
    width: 300px;
    bottom: auto;
    top: 61%;
    left: -36%;

    img {
      width: 100%;
    }
  }
}

@media (max-width: 576px) {
  .mobile-center {
    top: 145px;
  }

  .mobile-right {
    width: 312px;
    top: 23%;
    right: -50%;

    img {
      width: 100%;
    }
  }

  .mobile-left {
    width: 300px;
    bottom: auto;
    top: 54%;
    left: -60%;

    img {
      width: 100%;
    }
  }

  .mobile-qr {
    margin-top: 211px;
  }
}

.theme-light {
  .mobile-cta {
    .mobile-qr {
      .title {
        color: #0d0d0e !important;
      }

      .store-button {
        box-shadow: none;
      }
    }
  }
}
.react-multi-carousel-item {
  // width: 100% !important;
}
/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
  background-color: #fff;
  color: #000;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  font-size: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  bottom: 60px !important;
  position: fixed !important;
  right: 40px !important;
  z-index: 9999 !important;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

/* The popup chat - hidden by default */
.chat-popup {
  position: fixed !important;
  bottom: 80px !important;
  right: 30px !important;
  z-index: 9999 !important;
}
